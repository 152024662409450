<template>
	<div>
		<div class="page-title">
			<a>
				<router-link to="/unit_user/reseau/index">
					<el-button size="mini" icon="el-icon-back" class="mr-3">返回</el-button>
				</router-link>
			</a>
			<span style="font-size: 20px;">添加网格用户</span>
		</div>
		<div class="bg-white p-3 m-2">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" class="w-50">
				<el-form-item label="选择用户" prop="nickName">
					<el-input disabled v-model="form.nickName" placeholder="请选择用户">
						<template slot="append">
							<el-button class="button-item" @click.native="userListVisible = true" size="small">选择用户
							</el-button>
						</template>
					</el-input>
					<div flex="dif:left" style="flex-wrap:wrap;margin-top:20px">
						<div v-if="form.user_id > 0" @mouseenter="enter()" @mouseleave="leave"
							style="margin-right: 20px;position: relative;cursor: pointer;">
							<app-image mode="aspectFill" width="50px" height='50px' :src="user.avatarUrl">
							</app-image>
							<el-button class="del-btn" v-if="user_status == 1" size="mini" type="danger"
								icon="el-icon-close" circle @click="userDestroy()"></el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="姓名" prop="real_name">
					<el-input v-model="form.real_name"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="mobile">
					<el-input v-model="form.mobile"></el-input>
				</el-form-item>
				<el-form-item label="网格" prop="name">
					<el-input size="mini" style="width: 300px;" disabled v-model="form.name"></el-input>
					<el-button class="ml-1" size="mini" @click="openDialog()">选择</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit('form')" :loading="loading">确 定</el-button>

				</el-form-item>
			</el-form>
		</div>
		<el-dialog title="网格列表" :visible.sync="dialogVisible" top="3vh" width="65%" height="60%">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="网格名称" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
				</div>
				<div class="d-flex align-items-center m-1">
					<div class="label ">单位：</div>
					<el-select @change="handleFirstChange" v-model="search.first_id" placeholder="请选择支队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleSecondChange" v-model="search.second_id" placeholder="请选择大队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select v-model="search.third_id" placeholder="请请选择街道">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
				</div>
				<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
				</el-button>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" label="网格名称">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="单位">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.first_unit_name}}</span>
						<template v-if="scope.row.second_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.second_unit_name}}</span>
						</template>
						<template v-if="scope.row.third_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.third_unit_name}}</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<template v-if="scope.row.reseau_id == form.reseau_id">已选择</template>
						<el-button v-else type="text" size="mini" @click="selectReseau(scope.row)">选择</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
			</span>
		</el-dialog>
		<el-dialog title="添加用户" :visible.sync="userListVisible" append-to-body>
			<el-form @submit.native.prevent>
				<el-form-item>
					<el-input size="small" v-model="keyword" autocomplete="off" placeholder="用户昵称" style="width: 40%">
					</el-input>
					<el-button size="small" :loading="btnLoading" @click="searchUser()">查找用户
					</el-button>
				</el-form-item>
			</el-form>
			<el-table :data="user_list.data" style="width: 100%">
				<el-table-column prop="user_id" label="用户ID" width="80"></el-table-column>
				<el-table-column label="头像" width="280">
					<template slot-scope="scope">
						<app-image mode="aspectFill" style="float: left;margin-right: 8px" :src="scope.row.avatarUrl">
						</app-image>
						<div flex="dir:left cross:center">{{scope.row.nickName}}</div>
						<img class="platform-img" src="statics/img/store/wx.png" alt="">
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button v-if="form.user_id != scope.row.user_id" plain size="mini" type="primary"
							@click="userAdd(scope.$index, scope.row)">选择
						</el-button>
						<el-button v-else plain size="mini" type="primary" disabled>已选择</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-pagination v-if="user_list" background @current-change="pageUserChange" layout="prev, pager, next"
					:page-size="20" :total="user_list.total_count">
				</el-pagination>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				loading: false,
				dialogVisible: false,
				lists: [],
				firstList: [],
				secondList: [],
				thirdList: [],
				search: {
					s: 'store/unit.reseau/index',
					keyword: '',
					page: 1,
					first_id: 0,
					second_id: 0,
					third_id: 0,
				},
				form: {
					name: '',
					user_id: 0,
					nickName: "",
					real_name: '',
					mobile: '',
					reseau_id: 0,
				},
				rules: {
					name: [{
						required: true,
						message: '请选择网格',
						trigger: 'change'
					}, ],
					nickName: [{
						required: true,
						message: '请选择用户',
						trigger: 'change'
					}, ],
					real_name: [{
							required: true,
							message: '请输入姓名',
							trigger: 'change'
						},
						{
							max: 120,
							message: '最多输入120个字符',
							trigger: 'change'
						},
					],
					mobile: [{
							required: true,
							message: '请输入手机号',
							trigger: 'change'
						},
						{
							max: 11,
							message: '最多输入11个字符',
							trigger: 'change'
						},
					],
				},
				btnLoading: false,
				user_list: [],
				userListVisible: false,
				keyword: "",
				user_status: 0,
				user: {}
			}
		},
		created() {
			this.getUnitList()
			this.getList()
		},
		methods: {
			userDestroy() {
				this.user = {};
				this.form.user_id = 0;
				this.form.nickName = "";
			},
			userAdd(index, row) {
				this.form.user_id = row.user_id;
				this.form.nickName = row.nickName;
				this.user = row;
				this.userListVisible = false;
			},
			enter() {
				this.user_status = 1;
			},
			leave() {
				this.user_status = 0;
			},
			// 点击获取商品列表
			searchUser(page = 1) {
				this.btnLoading = true;
				this.axios({
					token: true,
					method: 'get',
					params: {
						s: 'store/user/index',
						keyword: this.keyword,
						page: page,
					},
				}).then(res => {
					this.btnLoading = false;
					if (res.data.code == 1) {
						this.user_list = res.data.data;
					}
				}).catch(res => {
					this.btnLoading = false;
				});
			},
			//分页
			pageUserChange(page) {
				this.searchUser(page);
			},
			handleFirstChange(first_id) {
				this.search.second_id = 0;
				this.search.third_id = 0;
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
					}
				}
			},
			handleSecondChange(second_id) {
				this.search.third_id = 0;
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
					}
				}
			},
			selectReseau(item) {
				this.form.name = item.name;
				this.form.reseau_id = item.reseau_id;
				this.dialogVisible = false;
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			openDialog() {
				// 显示弹出层
				this.dialogVisible = true
			},

			getUnitList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.firstList = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.axios({
							token: true,
							params: {
								s: 'store/unit_user.reseau/add',
							},
							data: {
								user: this.form
							},
							method: 'post',
						}).then(res => {
							this.loading = false;
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'unit_user.reseau/index'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
						}).catch(err => {
							this.loading = false;
						})
					}
				})
			}
		}
	}
</script>

<style>
</style>
